;(function() {
    $(document).ready(function() {
        var src = $('#google-map').attr('src');
        if (src){
            $('#google-map')
                .attr('src', '')
                .attr('data-src', src)
                .addClass('unveil');
        }
       $('img.unveil, iframe.unveil').each(function() {
           if (!$(this).attr('data-src') && $(this).attr('src')) {
               $(this).attr('data-src', $(this).attr('src'));
           }
       }).unveil(100, function () {
            $(this).load(function () {
                this.style.opacity = 1;

            }).each(function() {
                // to avoid caching issues we need to check the status set the opacity manually
                if(this.complete) {
                    this.style.opacity = 1;
                }
            });
        });

        $('.simple-banner-image').unveil(100, function() {
            var banner = this;
            var helper = new Image();

            helper.onload = function() {
                banner.style['background-image'] = 'url('+ this.src +')';
                banner.style.opacity = 1;
            };

            helper.src = $(this).data('src');
        });

        // trigger scroll on next tick
        window.setTimeout(function () {
            $(window).trigger('scroll');
        }, 0);
    });
})();

