(function() {
    /**
     * Overwrite default open cart scripts to make the search work more sufficiently
     */
    $(document).ready(function() {

        /**
         * Parses the search input and performs the search.
         * @param {jQuery} $searchContainer Container that hold the search input.
         */
        function fireSearch($searchContainer) {
            if (!$searchContainer) {
                return;
            }
            var value = $searchContainer.find('input[name="search"]').val() || '';
            window.location.href = '/search&search=' + encodeURIComponent(value);
        }

        /**
         * The search button handler.
         */
        $('.btn-header-search').off('click').on('click', function (e) {
            e.preventDefault();
            fireSearch($(this).closest('#search'));
            return false;
        });

        /**
         * The search input handler.
         */
        $('input[name="search"]').off('keydown').on('keydown', function(e) {
            if (e.keyCode === 13) {
                e.preventDefault();
                fireSearch($(this).closest('#search'));
                return false;
            }
        });
    });
})();
