;(function ($) {
    // TODO can be improved
    $.fn.parallax = function () {
        return $(this).each(function () {
            var $scrollElement = $(this);
            $scrollElement.css({
                'background-attachment': 'fixed',
                'background-position': '50% 50%'
            });

            $(window).scroll(function (event) {
                var scroll = $(window).scrollTop();
                if (scroll) {
                    scroll = '-' + scroll / 80;
                }

                if (visible($scrollElement)) {
                    $scrollElement.css({
                        'transform': 'translate3d(0, ' + scroll + 'px, 0',
                        '-webkit-transform': 'translate3d(0, ' + scroll + 'px, 0',
                        '-ms-transform': 'translate3d(0, ' + scroll + 'px, 0',
                        '-moz-transform': 'translate3d(0, ' + scroll + 'px, 0',
                        '-o-transform': 'translate3d(0, ' + scroll + 'px, 0'
                    });
                }
            });
        });
        function visible(el) {
            //special bonus for those using jQuery
            if (typeof jQuery === "function" && el instanceof jQuery) {
                el = el[0];
            }

            var rect = el.getBoundingClientRect();

            return (
                rect.top >= 0 &&
                rect.left >= 0 &&
                rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /*or $(window).height() */
                rect.right <= (window.innerWidth || document.documentElement.clientWidth) /*or $(window).width() */
            );
        }
    };

    // $(document).ready(function () {
    //     $('.simple-banner-image').parallax();
    // });
})(jQuery);

