// Cart add remove functions
/**
 * Disclaimer:
 * This object is supposed to overwrite the the global object "cart" for reasons of customization
 */
;(function () {
    var getURLVar = window.getURLVar;

    function onBeforeSend() {
        $('#cart > button').button('loading');
    }

    function onComplete() {
        $('.tooltip').tooltip('hide');
        $('#cart > button').button('reset');
    }

    function onError (xhr, ajaxOptions, thrownError) {
        console.warn(thrownError, xhr.statusText, xhr, xhr.responseText);
        //alert(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
    }

    function onSuccess(json) {
        // Need to set timeout otherwise it wont update the total
        setTimeout(function () {
            if (!json || !json.hasOwnProperty('total')) {
                return;
            }

            $('#cart > button').html(
                '<span id="cart-total"><i class="fa fa-shopping-cart"></i> ' + json['total'] + '</span>'
            );
        }, 100);

        if (
            ['/cart', '/checkout'].indexOf(window.location.pathname) !== -1
            || ['checkout/cart' , 'checkout/checkout'].indexOf(getURLVar('route')) !== -1
        ) {
            updateCart(json);
        } else {
            updateUI(json);
        }
    }

    function updateCart() {
        $.ajax({
            url: window.location.href,
            type: 'get',
            success: function(data) {
                var response = $('<div></div>').append($(data)).get(0);
                var $content = $(response).find('div#content');
                var $badge = $(response).find('header .counter.badge');
                var $cart = $content.find('form.cart');
                var $cartTotals = $content.find('.cart-totals');
                var $alert = $content.find('.alert');
                var $cartWeight = $content.find('.cart-weight');

                $('form.cart').html($cart.html());
                $('.cart-weight').html($cartWeight.html());
                $('.cart-totals').html($cartTotals.html());
                $('header .counter.badge').html($badge.html());
                $('.alert').remove();
                $('.breadcrumb').after($alert);
            },
            error: function (xhr, text) {
                console.warn(xhr, text);
            }
        });
    }

    function fadeOutBox(timer){
        var alertSuccess = $('.alert.alert-success');
        if (timer) {
            alertSuccess.trigger('timer.close.bs.alert');
            return;
        }

        alertSuccess.alert('close');
    }

    $("body").click (
        function(e)
        {
            if(e.target.className !== "alert-success")
            {
                fadeOutBox();
            }
        }
    );

    function updateUI(json) {
        if(json.success) {
            $('footer').after($('<div class="alert alert-success"><div><i class="fa fa-check-circle"></i> ' +
                json.success +
                '<button type="button" class="close" data-dismiss="alert">&times;</button>' +
                '</div></div>').fadeIn(250)
            );
        }

        fadeOutBox(true);

        if ($('#cart').length) {
            $('#cart > ul').load('/index.php?route=common/cart/info ul li');
        }

        if (typeof json.cart_items_count !== 'undefined') {
            $('.cart-link .counter').html(json.cart_items_count);
        }
    }

    var cart = {
        submitProduct: function () {
            $.ajax({
                url: '/index.php?route=checkout/cart/add',
                type: 'post',
                data: $('form.product-configuration').serialize(),
                dataType: 'json',
                beforeSend: onBeforeSend,
                complete: onComplete,
                success: function(json) {
                    $('.alert, .text-danger').remove();
                    $('.form-group').removeClass('has-error');

                    if (json['error']) {
                        if (json['error']['option']) {
                            for (i in json['error']['option']) {
                                var element = $('#input-option' + i.replace('_', '-'));

                                if (element.parent().hasClass('input-group')) {
                                    element.parent().after('<div class="text-danger">' + json['error']['option'][i] + '</div>');
                                } else {
                                    element.after('<div class="text-danger">' + json['error']['option'][i] + '</div>');
                                }
                            }
                        }

                        if (json['error']['recurring']) {
                            $('select[name=\'recurring_id\']').after('<div class="text-danger">' + json['error']['recurring'] + '</div>');
                        }

                        // Highlight any found errors
                        $('.text-danger').parent().addClass('has-error');
                    }

                    if (json['success']) {
                        $('#cart > button').html('<i class="fa fa-shopping-cart"></i> ' + json['total']);
                        updateUI(json);
                    }
                },
                error: onError
            });
        },
        add: function (product_id, quantity) {
            $.ajax({
                url: '/index.php?route=checkout/cart/add',
                type: 'post',
                data: 'product_id=' + product_id + '&quantity=' + (typeof(quantity) != 'undefined' ? quantity : 1),
                dataType: 'json',
                beforeSend: onBeforeSend,
                complete: onComplete,
                success: function (json) {
                    $('.alert, .text-danger').remove();

                    if (json['redirect']) {
                        window.location = json['redirect'];
                    }

                    if (json['success']) {

                        onSuccess(json);

                    }
                },
                error: onError
            });
        },
        update: function () {
            $.ajax({
                url: '/index.php?route=checkout/cart/edit',
                type: 'post',
                data: $('form.cart').serializeArray(),
                beforeSend: onBeforeSend,
                complete: onComplete,
                success: onSuccess,
                error: onError
            });
        },
        remove: function (key) {
            $.ajax({
                url: '/index.php?route=checkout/cart/remove',
                type: 'post',
                data: 'key=' + key,
                dataType: 'json',
                beforeSend: onBeforeSend,
                complete: onComplete,
                success: function(json) {
                    if ($('.cart-product').length === 1) {
                        window.location.reload();
                        return;
                    }

                    onSuccess(json);
                },
                error: onError
            });
        }
    };

    window.cart = cart;
})();