(function() {

    var options = {
        "privacyUrl": "/privacy", /* Privacy policy url */

        "hashtag": "#tarteaucitron", /* Open the panel with this hashtag */
        "cookieName": "cookieconsent", /* Cookie name */
        "orientation": "bottom", /* Banner position (top - bottom) */
        "showIcon": false,
        "showAlertSmall": true, /* Show the small banner on bottom right */
        "cookieslist": true, /* Show the cookie list */

        "adblocker": false, /* Show a Warning if an adblocker is detected */
        "AcceptAllCta" : true, /* Show the accept all button when highPrivacy on */
        "highPrivacy": true, /* Disable auto consent */
        "handleBrowserDNTRequest": true, /* If Do Not Track == 1, disallow all */

        "removeCredit": true, /* Remove credit link */
        "moreInfoLink": true, /* Show more info link */
        "useExternalCss": false, /* If false, the tarteaucitron.css file will be loaded */
        //"cookieDomain": ".my-multisite-domaine.fr", /* Shared cookie for subdomain website */
        "mandatory": true,
        "mandatoryCta": true,
        "readmoreLink": "/privacy#cookies" /* Change the default readmore link pointing to opt-out.ferank.eu */
    };

    if (typeof window.tarteaucitron === 'undefined') {
        window.addEventListener('load', init);
        return;
    }

    init();

    function init() {
        window.tarteaucitronCustomText = {
            "personalize": "Cookie Einstellungen",
            "disclaimer": "Wenn Sie unter 16 Jahre alt sind und Ihre Zustimmung zu freiwilligen Diensten geben möchten, müssen Sie Ihre Erziehungsberechtigten um Erlaubnis bitten.</br></br> Wir verwenden Cookies und andere Technologien auf unserer Website. Einige von ihnen sind essenziell, während andere uns helfen, diese Website und Ihre Erfahrung zu verbessern. Personenbezogene Daten können verarbeitet werden (z. B. IP-Adressen), z. B. für personalisierte Anzeigen und Inhalte oder Anzeigen- und Inhaltsmessung. Weitere Informationen über die Verwendung Ihrer Daten finden Sie in unserer <a href='/privacy'>Datenschutzerklärung</a>. Hier finden Sie eine Übersicht über alle verwendeten Cookies. Sie können Ihre Einwilligung zu ganzen Kategorien geben oder sich weitere Informationen anzeigen lassen und so nur bestimmte Cookies auswählen. ",
            "mandatoryText": "Diese Seite nutzt Cookies, um die Bedienung der Website zu ermöglichen, diese können nicht deaktiviert werden.</br>" +
                "</br>Folgende Cookies sind notwendig:</br></br>" +
                "cookieconsent: Benutzereinstellung zur Cookienutzung.</br>" +
                "currency: Information zur voreingestellten Währung.</br>" +
                "language: Information über die voreingestellte Sprache.</br>"
        };
        window.tarteaucitron.init(options);
    }

})();
