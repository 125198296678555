/**
 * Created by Vivan on 14.04.2017.
 */

$(document).ready(function(){

    // Der Button wird mit JavaScript erzeugt und vor dem Ende des body eingebunden.
    if(!$("body").find('.back-to-top').length) {
        var back_to_top_button = ['<a href="#top" title="nach oben" class="back-to-top fa-arrow-up btn-dafault"></a>'].join("");
        $("body").append(back_to_top_button);

        // Der Button wird ausgeblendet
        $(".back-to-top").hide();

        // Funktion für das Scroll-Verhalten
        $(function () {
            $(window).scroll(function () {
                if ($(this).scrollTop() > 100) { // Wenn 100 Pixel gescrolled wurde
                    $('.back-to-top').fadeIn();
                } else {
                    $('.back-to-top').fadeOut();
                }
            });

            $('.back-to-top').click(function () { // Klick auf den Button
                $('body,html').animate({
                    scrollTop: 0
                }, 800);
                return false;
            });
        });
    }


});
