;(function () {
    'use strict';

    function numberInputListener(e) {
        var inputs = document.getElementsByTagName('input');

        for (var i = 0; i < inputs.length; i++) {
            if (inputs[i] !== e.target) {
                continue;
            }
            if (inputs[i].type.toLowerCase() === 'number') {
                onChange.call(e.target, e);
            }
        }
    }


    $(document).on('click', '.number-spinner button', function () {
        var btn = $(this);
        var input = btn.closest('.number-spinner').find('input');
        btn.closest('.number-spinner').find('button').prop("disabled", false);

        if (btn.attr('data-dir') == 'up') {
            if (input.attr('max') == undefined || parseInt(input.val()) < parseInt(input.attr('max'))) {
                input.val(parseInt(input.val()) + 1);
            } else {
                btn.prop("disabled", true);
            }
        }else {
            if ( input.attr('min') == undefined || parseInt(input.val()) > parseInt(input.attr('min')) ) {
                input.val(parseInt(input.val())-1);
            }else{
                btn.prop("disabled", true);
            }
        }
    });


    function onChange(e) {
        var val = parseInt(this.value || 1, 10);
        if (typeof val !== 'number' || isNaN(val)) {
            this.value = 1;
            return;
        }

        if (e.which) {
            if (e.which === 8 && !this.value) {
                return;
            }
            if (e.which === 46) {
                this.select();
                return;
            }
        }

        if (this.value > 9999) {
            this.value = 9999;
            return;
        }
        if (this.value <= 0) {
            this.value = 1;
        }
    }

    document.addEventListener('change', numberInputListener);
    document.addEventListener('keyup', numberInputListener);
})();