;(function(){
    var $couponInput = $('input[name=\'coupon\']');
    var $button = $('#button-coupon');
    var $buttonRemove = $('<div data-toggle="tooltip" data-original-title="Entfernen" data-trigger="manual" class="btn remove-coupon"><i class="fa fa-trash"></i></div>');

    function toggleDelete() {
        if ($couponInput.val()) {
            $buttonRemove.on('click', onRemove);
            $button.after($buttonRemove);
        } else {
            $buttonRemove.remove();
        }
    }

    function fadeOutBox(timer) {
        var alertSuccess = $('.alert.alert-success');
        if (timer) {
            alertSuccess.trigger('timer.close.bs.alert');
            return;
        }

        alertSuccess.alert('close');
    }

    function reload() {
        $.ajax({
            url: window.location.href,
            type: 'get',
            success: function(json) {
                var $response = $(json);
                var $cartTotals = $response.find('.cart-totals');
                var $alert = $response.find('.alert');

                $('.cart-totals').html($cartTotals.html());
                $('.alert').remove();
                $('footer').after($alert).fadeIn(250);
                fadeOutBox(true);
            }
        });
    }

    function onRemove() {
        $couponInput.val('');
        $.ajax({
            url: '/index.php?route=extension/total/coupon/coupon',
            type: 'post',
            data: 'coupon=' + encodeURIComponent(''),
            dataType: 'json',
            beforeSend: function() {
                $('.tooltip').tooltip('hide');
                $('.help-block').remove();
            },
            success: function(json) {
                toggleDelete();
                reload();
            }
        });
    }

    toggleDelete();

    $button.on('click', function() {
        $.ajax({
            url: '/index.php?route=extension/total/coupon/coupon',
            type: 'post',
            data: 'coupon=' + encodeURIComponent($couponInput.val()),
            dataType: 'json',
            beforeSend: function() {
                $('.help-block').remove();

                $button.button('loading');
            },
            complete: function() {
                $button.button('reset');
            },
            success: function(json) {
                toggleDelete();

                if (json['error'] && $couponInput.val()) {
                    $button.closest('.input-group').after(
                    '<p class="help-block">' + json['error'] +'</p>'
                    );
                    return;
                }

                reload();
            }
        });
    });
})();