;(function() {
    $(document).ready(function() {
        if (typeof $.fn.datetimepicker === 'function') {
            $('.date').datetimepicker({
                pickTime: false,
                format: 'DD.MM.YYYY'
            });

            $('.time').datetimepicker({
                pickDate: false,
                format: 'HH:mm'
            });

            $('.datetime').datetimepicker({
                pickDate: true,
                pickTime: true,
                format: 'DD.MM.YYYY HH:mm'
            });

            $('.datetime, .date, .time').find('input').click(function() {
                $(this).parent().find('button').click();
            });

            window.setTimeout(function() {
                $('.datetime, .date, .time').datetimepicker();
                $('.custom-field').not('.required').find('input, textarea, select').each(function(){
                    if (this.type === 'text' || this.type === 'textarea') {
                        this.placeholder = 'Optional';
                    } else {
                        $('label[for=' + this.id + ']').append(' (optional)');
                    }
                });
            }, 400);
        }
    });
})();