/**
 * Handle the tooltip display on different platforms.
 * On a computer tooltips should be triggered on hover but on mobile devices they should be shown on touch
 */
(function() {
    if (!$('[data-toggle="tooltip"]').length || typeof $.fn.tooltip !== 'function') {
        return;
    }

    var isMobile = navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i);
    var selector = '[data-toggle="tooltip"]';
    if (!isMobile) {
        $(document).on('mouseenter mouseleave', selector, function (e) {
            $(this).tooltip(e.type === 'mouseenter' ? 'show' : 'hide');
        });
    } else {
        $(document).on('touchstart touchend', selector, function (e) {
            $(this).tooltip(e.type === 'touchstart' ? 'show' : 'hide');
        });
    }
})();