/**
 * Created by Vivan on 12.08.2017.
 */

$(document).ready(function(){

    /**
     * Funktion für das Hinzufügen der Chalkboard Optik:
     * html-Module, welche ein Element .chalkboard-inner besitzen wird die Klasse .chalkboard per Javascript ergaenzt
     */
    $(function () {
        $(".chalkboard-inner").closest(".html-module").addClass("chalkboard");
    });
});