(function () {
    var timer;
    function closeAlert() {
        var $alertSuccess = $('.alert.alert-success');
        if ($alertSuccess.length > 0) {
            $alertSuccess.fadeOut('250', function() {
                $(this).remove();
            });
        }
    }

    function closeAlertTimed() {
        window.clearTimeout(timer);
        timer = window.setTimeout(function() {
            closeAlert();
        }, 3000);
    }

    $(document)
        .ready(closeAlertTimed)
        .on('close.bs.alert', '.alert.alert-success', closeAlert)
        .on('timer.close.bs.alert', '.alert.alert-success', closeAlertTimed);
})();