;(function(){
    $(document).ready(function (){
        var $menu = $('#menu');
        var $header = $('header');
        var height;
        var stickyThreshold;

        // Add wrappers to prevent elements jumping when "sticky" is set
        $header.wrap('<div id="sticky-header-wrapper">');
        var wrapperHeader = $('#sticky-header-wrapper');

        if($menu.length) {
            $menu.wrap('<div id="sticky-menu-wrapper">');
            var wrapperMenu = $('#sticky-menu-wrapper');
        }

        initStickyMenus();
        initAnchorNav();

        /**
         * Init the anchor navigation.
         */
        function initAnchorNav() {
            $(window).resize(positionAnchor);
            positionAnchor();
            bindAnchor();
            setActiveSection();

            /**
             * Repositions the anchor considering the sticky menus heights.
             */
            function positionAnchor() {
                var height = getStickyMenusHeight();
                $('.anchor').css({
                    height: height,
                    marginTop: -height,
                    visibility: 'hidden'
                });
            }

            /**
             * Bind click event listener and animated the scroll position to the link target anchor.
             */
            function bindAnchor(){
                $menu.find('a[href*="#"]:not([href="#"])').on('click', function(e) {
                    e.preventDefault();
                    var target = $(e.delegateTarget.hash);
                    var duration = $(this).hasClass('active') ? 100 : 800;
                    $menu.find('.collapse').collapse('hide');

                    if(!target.length) {
                        return;
                    }

                    var scrollTop = parseInt(target.offset().top, 10);
                    if (scrollTop === window.scrollY) {
                        return;
                    }

                    $('html, body').animate({
                        scrollTop: scrollTop
                    }, duration);
                });
            }

            /**
             * Initializes the waypoint jquery plugin that spies on the the
             * scrollpos and sets active state to the nav links.
             */
            function setActiveSection() {
                var anchors = $('.anchor');
                // Handler for downwards navigation
                anchors.waypoint({
                    group: 'down', // just a name, no functionality
                    handler: function (direction) {
                        if (direction === 'up') {
                            return;
                        }

                        setActive(this.element.id);
                    }
                });

                // Handler for upwards navigation
                anchors.waypoint({
                    group: 'up', // just a name, no functionality
                    offset: window.Waypoint.viewportHeight, // waypoint should be triggered early when scrolling up
                    handler: function (direction) {
                        if (direction === 'down') {
                            return;
                        }
                        setActive(this.previous().element.id);
                    }
                });

                function setActive(id) {
                    $menu.find('a').removeClass('active');
                    $menu.find('a[href="#' + id + '"]').addClass('active');
                }
            }
        }

        /**
         * Initialize the sticky menus.
         */
        function initStickyMenus(){
            $(window)
            // When the user scrolls the page, execute myFunction
                .scroll(setSticky)
                // When the window resize recalculate sizes
                .resize(stickyNav)
                .resize();

            /**
             * Calculate sizes and thresholds
             */
            function stickyNav() {
                height = $header.height();
                if ($menu.length) {
                    wrapperMenu.css('height', getMenuHeight() + 'px');
                }
                wrapperHeader.css('height', height + 'px');
                if (wrapperMenu) {
                    stickyThreshold = wrapperMenu.offset().top - height;
                }
                setSticky();
            }

            /**
             * Adds the sticky class to the menu when the users reaches its scroll position.
             * Removes "sticky" when the user leaves the scroll position
             */
            function setSticky() {
                if (!$menu.length) {
                    return;
                }
                if (window.pageYOffset >= stickyThreshold) {
                    $menu
                        .addClass('sticky')
                        .css('top', height + 'px');
                } else {
                    $menu
                        .removeClass('sticky')
                        .css('top', 0);
                }
            }
        }

        function getMenuHeight() {
            return $menu.find('.navbar-header').height() || $menu.height();
        }

        function getStickyMenusHeight () {
            return getMenuHeight() + $header.height();
        }
    });
})();